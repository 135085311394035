


















































import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class Unsubscribe extends mixins(Navigation) {
  loading = true;
  verified = false;

  private async created() {
    this.$store
      .dispatch("newsletter/unsubscribe", {
        token: this.$route.params.token,
        email: this.$route.query.email,
      })
      .then(() => {
        this.verified = true;
      })
      .catch(() => {
        this.verified = false;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
}
